/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "두 번째 에피소드는 애플 신제품 발표, GitHub 장애원인 보고서, 클라우드 시대의 DB AWS Aurora를 이야기 했어요."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://m.post.naver.com/viewer/postView.nhn?volumeNo=16993911&memberNo=883&vType=VERTICAL"
  }, "[총정리] 쉽게 보는 애플 발표 - 새로운 맥북에어, 맥미니, 아이패드 프로 달라진점.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.github.com/2018-10-30-oct21-post-incident-analysis/"
  }, "GitHub 장애 보고서")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://githubengineering.com/mysql-high-availability-at-github/"
  }, "MySQL High Availability at GitHub")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cnbc.com/2018/10/23/amazon-move-off-oracle-caused-prime-day-outage-in-warehouse.html"
  }, "아마존 서비스 DB를 Oracle에서 Aurora로 변경한것이 프라임 데이 장애의 원인중 하나라고 이야기 하는 기사 - CNBC")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/andy_pavlo/status/1055154051875332097"
  }, "CNBC 기자가 아침일찍 전화해서 항의 했다는 트윗 - Andy Pavlo")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/groups/awskrug/permalink/1561885513913467/"
  }, "AWS 서울리전 인스턴스 부족")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2018/10/Amazon-EC2-now-offers-On-Demand-Capacity-Reservations/"
  }, "Amazon EC2, 이제 온디맨드 용량 예약 제공")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.datadoghq.com/apm/"
  }, "Datadog APM")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://newrelic.com/products/application-monitoring"
  }, "New Relic APM")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.github.com/2018-10-26-github-and-microsoft/"
  }, "GitHub 새 CEO Nat Friedman 취임 인삿말")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Nat_Friedman"
  }, "Nat Friedman - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2018/10/28/biggest-software-acquisition/"
  }, "IBM이 redhat 인수")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.elastic.co/kr/blog/ze-bell-has-rung-thank-you-users-customers-and-partners"
  }, "Elastic 뉴욕증시 상장")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://skiplang.com/"
  }, "Skip 언어")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
